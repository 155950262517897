//TODO: Skipping tests for now. Add test coverage and remove this line in LEARN-11019
/* istanbul ignore file */
import { useCallback, useEffect, useState } from 'react'

import { QueryType } from '@cfc/content-service-common'
import ContentServiceClient from '@cfc/content-service-sdk'
import { backoff } from '@cfc/exponential-backoff'
import {
  CACHE,
  ENTRY_ID_PARAM_ERROR,
  ENVIRONMENT,
  FETCH_PRESENTATION_ERROR,
  FETCH_PRESENTATION_ERROR_UNPUBLISHED,
  FORMAT,
  INCLUDE,
  LOAD_PRESENTATION_ERROR,
  LOCALE,
  MOCK_DATA,
  PREVIEW_PARAM_ERROR,
  SUCCESS,
} from 'components/AppWrapper/constants'
import Ajax from 'lib/Ajax'
import hydrate from 'lib/APIConnector/hydrate'
import rehydrateRedux from 'lib/APIConnector/rehydrateRedux'
import {
  CONTENT_FUNCTION_URL,
  IS_CONNECTED_TO_API,
  SUBDOMAIN,
} from 'lib/config'
import { hydrateCmi, updateCmiLocale } from 'lib/state/actions/cmi'
import {
  addTheme,
  initializePlayer,
  setCurriculumPreviewMode,
  setFamilyMode,
  setHighSchoolMode,
  setHighSchoolPreviewMode,
  setVtts,
  togglePrepMode,
} from 'lib/state/actions/player'
import configureStore from 'lib/state/store'
import { getLocationData, getParams } from 'lib/url'
import { getBoolFromString } from 'utils/boolHelper'
import { isFlagOn } from 'utils/featureFlags'
import { getLocaleFromSession, LOCALES } from 'utils/locale'
import { getPlayerSessionTimeManager } from 'utils/sessionHelper'

import { useUserContext } from '../lib/UserContext/provider'

// This function should be here temporaly, it's used only to demonstrate
// content can be fetched from content-service (program flex)
function fetchContentDemo(userContext) {
  if (!userContext) {
    return
  }

  const client = new ContentServiceClient({
    environment: SUBDOMAIN.startsWith('-') ? SUBDOMAIN.substring(1) : SUBDOMAIN,
  })
  client
    .getContent({
      // this entry is not important, it's just the simplest/smallest we could find
      entryId: 'ClN6KJLPh3jAZp0GatoMs',
      queryType: QueryType.redirectRule,
    })
    .then(data => {
      // eslint-disable-next-line no-console
      console.log('fetchContentDemo data', data)
    })
}

export const useInitApp = () => {
  const [initialStore, setInitialStore] = useState()
  const [error, setError] = useState()

  const { data: userContext, error: userContextError } = useUserContext()

  const fetchContent = useCallback(
    userContext => fetchContentDemo(userContext),
    [userContext],
  )
  if (userContextError) {
    console.error('error fetching user context', userContextError)
  }

  // just for POC purposes, feel free to remove after implmenting real fetch content pattern
  if (isFlagOn(['feature-learn-18511-player-program-flex'])) {
    fetchContent(userContext)
  }

  useEffect(() => {
    prepareStore()
  }, [])

  const prepareStore = async () => {
    let initialStore = configureStore()

    await hydrateStore(initialStore)
    initialStore = await populateStoreWithParams(initialStore)

    setInitialStore(initialStore)
  }

  const hydrateStore = async store => {
    const hydratedData = await hydrate()
    //Hydrate the store with data from the api and feature flags
    hydratedData && (await store.dispatch(hydrateCmi(hydratedData)))
  }

  const loadPresentationJson = async (
    contentfulId,
    preview,
    token,
    cache,
    locale,
  ) => {
    const ajaxUrl = new URL(CONTENT_FUNCTION_URL)

    contentfulId && ajaxUrl.searchParams.append('entryId', contentfulId)
    // This is only needed in preview mode
    preview &&
      ajaxUrl.searchParams.append(ENVIRONMENT, 'preview.contentful.com')
    // Tell the api to not format our data
    ajaxUrl.searchParams.append(FORMAT, false)
    // Get all the levels of data
    ajaxUrl.searchParams.append(INCLUDE, 10)

    if (cache) {
      ajaxUrl.searchParams.append(CACHE, cache)
    }
    ajaxUrl.searchParams.append(LOCALE, locale)

    const url = ajaxUrl.toString()

    try {
      return await backoff(() => Ajax.get(url, { token }))
    } catch (error) {
      const errorMessage = !preview
        ? FETCH_PRESENTATION_ERROR_UNPUBLISHED
        : FETCH_PRESENTATION_ERROR
      console.error(errorMessage)
    }
  }

  const populateStoreWithParams = async store => {
    const { params, search } = getLocationData()

    if (!search || search === '') {
      console.error(PREVIEW_PARAM_ERROR)

      return
    }

    const paramObj = getParams(params, store) || {}
    const {
      contentfulId,
      preview: previewString,
      themeId,
      token,
      cache,
      isFamilyMode,
      isCurriculumPreviewMode,
      isHighSchoolMode,
      isHighSchoolPreviewMode,
      isPrepMode,
    } = paramObj

    let { locale } = paramObj

    //get locale from url first. If not, check if we have in sessionStorage from toggle. If not there then default to english
    if (!locale) {
      const checkForLocaleInSessionStorage = getLocaleFromSession()
      if (checkForLocaleInSessionStorage) {
        locale = checkForLocaleInSessionStorage
      } else {
        locale = LOCALES.EN_US
      }
    }

    if (!contentfulId) {
      console.error(ENTRY_ID_PARAM_ERROR)

      return
    }

    const previewBool = getBoolFromString(previewString)

    if (previewBool === null) {
      console.error(PREVIEW_PARAM_ERROR)

      return
    }

    const hasParams = Object.keys(params).length > 0
    const hasMockData = params.has(MOCK_DATA)
    const useMockData = hasMockData || (!hasParams && !contentfulId)
    let presentationJson, entryFromCache

    if (!useMockData) {
      presentationJson = await loadPresentationJson(
        contentfulId,
        previewBool,
        token,
        cache,
        locale,
      )
      entryFromCache = await loadPresentationJson(
        contentfulId,
        previewBool,
        token,
        cache,
        '*',
      )
    }

    await store.dispatch(addTheme(themeId))
    await store.dispatch(updateCmiLocale(locale))
    store = await setPresentation(
      presentationJson,
      store,
      isPrepMode,
      locale,
      isFamilyMode,
      isCurriculumPreviewMode,
      isHighSchoolMode,
      isHighSchoolPreviewMode,
    )
    const vttByEntries = getVttsByEntries(entryFromCache)
    if (vttByEntries) {
      await store.dispatch(setVtts(vttByEntries))
    }

    return store
  }

  const getVttsByEntries = presentation => {
    try {
      const sections =
        presentation?.data?.fields?.sections[LOCALES.EN_US] || null //sections
      let vttEntryList = {}
      if (sections) {
        sections.map(section => {
          const slides = section?.fields?.slides[LOCALES.EN_US] || null //slides per Section
          if (slides) {
            slides.map(slide => {
              const fieldsLayout = slide?.fields?.layout[LOCALES.EN_US] || null
              const column = fieldsLayout?.fields?.column1 || null
              if (column && column[LOCALES.EN_US]) {
                const { videoRef } = column[LOCALES.EN_US][0]?.fields || null

                if (videoRef && videoRef[LOCALES.EN_US]) {
                  const videRefFields = videoRef[LOCALES.EN_US]?.fields || null
                  const vttFile =
                    videRefFields?.vttFile[LOCALES.EN_US][0] || null
                  if (!videRefFields && vttFile) {
                    return null
                  }

                  const vtFile = vttFile?.fields?.file || null
                  vttEntryList = { ...vttEntryList, [slide.sys.id]: vtFile }
                }
              }
            })
          }
        })
      }
      return vttEntryList
    } catch (error) {
      return null
    }
  }

  const setPresentation = async (
    presentationJson,
    store,
    isPrepMode,
    locale,
    isFamilyMode,
    isCurriculumPreviewMode,
    isHighSchoolMode,
    isHighSchoolPreviewMode,
  ) => {
    const { data: presentation, status } = presentationJson || {}
    const didFail = status !== SUCCESS

    if (didFail) {
      console.error(LOAD_PRESENTATION_ERROR)

      setError(presentationJson)
      return store
    }
    await store.dispatch(togglePrepMode(isPrepMode))
    await store.dispatch(setFamilyMode(isFamilyMode))
    await store.dispatch(setCurriculumPreviewMode(isCurriculumPreviewMode))
    await store.dispatch(setHighSchoolMode(isHighSchoolMode))
    await store.dispatch(setHighSchoolPreviewMode(isHighSchoolPreviewMode))
    await store.dispatch(initializePlayer(presentation))
    await rehydrateRedux(store, locale)

    IS_CONNECTED_TO_API && getPlayerSessionTimeManager(store)

    const { fields = {} } = presentation
    const { displayTitle = {} } = fields

    if (!IS_CONNECTED_TO_API && displayTitle) {
      document.title = displayTitle
    }

    return store
  }

  return { initialStore, error }
}
