import React, { createContext, useContext, useEffect, useState } from 'react'

import { simpleFetchUserContext } from 'auth/stores/userContext/operations'
import PropTypes from 'prop-types'
import { isFlagOn } from 'utils/featureFlags'

const UserContextContext = createContext({
  data: null,
  error: null,
})

function formatToken(token) {
  return token.startsWith('Bearer ') ? token : `Bearer ${token}`
}

function defaultTokenResolver() {
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')

  if (token) {
    return formatToken(token)
  }

  if (window.parent.__CFC_TOKEN__) {
    const token = window.parent.__CFC_TOKEN__
    return formatToken(token)
  }

  return ''
}

/**
 * Custom hook to fetch user context data.
 *
 * @param {Object} options - Configuration options for the hook.
 * @param {Function} [options.tokenResolver=defaultTokenResolver] - Function to resolve the token.
 * @param {string} options.baseUrl - The base URL for the API request.
 * @returns {Object} - An object containing the fetched data and any error encountered.
 * @returns {any} return.data - The fetched user context data.
 * @returns {Error|null} return.error - The error encountered during the fetch, if any.
 */
function useUserContextQuery(options) {
  const { tokenResolver = defaultTokenResolver, baseUrl } = options ?? {}
  const [data, setData] = useState()
  const [error, setError] = useState(null)
  const token = tokenResolver()

  useEffect(() => {
    async function fetchUserContext() {
      try {
        const data = await simpleFetchUserContext(baseUrl, token)
        setData(data)
      } catch (err) {
        setError(err)
      }
    }

    if (isFlagOn(['feature-learn-18511-player-program-flex'])) {
      fetchUserContext()
    }
  }, [options, token])

  return { data, error }
}

/**
 * UserContextProvider component that provides user context to its children.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components that will receive the user context.
 * @param {Object} props.options - Options to be passed to the useUserContextQuery hook.
 * @returns {JSX.Element} The UserContextContext.Provider component with user context value.
 */
export const UserContextProvider = ({ children, options }) => {
  const userContext = useUserContextQuery(options)

  return (
    <UserContextContext.Provider value={userContext}>
      {children}
    </UserContextContext.Provider>
  )
}

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.shape({
    tokenResolver: PropTypes.func,
    baseUrl: PropTypes.string.isRequired,
  }).isRequired,
}

function defaultSelector(userContext) {
  return userContext
}

/**
 * Custom hook to use the UserContext.
 *
 * This hook provides access to the UserContext, allowing components to
 * consume the context values.
 *
 * @param {Object} options - Options for the hook.
 * @param {Function} [options.selector=defaultSelector] - Function to select a part of the context data.
 * @returns {Object} The context data and any error encountered.
 * @returns {any} return.data - The selected context data.
 * @returns {Error} return.error - Any error encountered while fetching the context data.
 */
export function useUserContext(options) {
  const { selector = defaultSelector } = options ?? {}
  const { data, error } = useContext(UserContextContext)

  return {
    error,
    data: selector(data),
  }
}
