import surveyBgImage from '../../../assets/images/k8-g6-survey-slide.jpg'

import { default as baseTheme } from './generic'

const colors = {
  orange: '#E1523D',
  pink: '#A13CC6',
  ltblue: '#BBD7D8',
  blue: '#3F5C8B',
  teal: '#31BFD9',
  purple: '#50247F',
}

const fonts = {
  display: {
    fontFamily: 'amboy-inline',
    fontSize: '1.83em',
    fontWeight: 400,
    lineHeight: 'normal',
    margin: 0,
  },
  p: {
    fontFamily: 'filson-pro, sans-serif',
    fontSize: '2.05rem',
    lineHeight: '3.3125rem',
    fontWeight: '300',
    fontStyle: 'normal',
  },
  li: {
    fontFamily: 'filson-pro, sans-serif',
    fontSize: '207%',
    lineHeight: '49.5px',
    fontWeight: '300',
    fontStyle: 'normal',
  },
  generic: {
    fontFamily: 'filson-pro, sans-serif',
    fontSize: '175%',
    lineHeight: '49.5px',
    fontWeight: '300',
    fontStyle: 'normal',
  },
  h1: {
    fontSize: '3rem',
    fontFamily: 'quagmire, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '4rem',
  },
  h2: {
    fontSize: '250%',
    fontFamily: 'quagmire, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  h3: {
    fontSize: '238%',
    fontFamily: 'quagmire, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
    margin: '0',
  },
  h4: {
    fontSize: '225%',
    fontFamily: 'quagmire, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  h5: {
    fontSize: '219%',
    fontFamily: 'quagmire, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  h6: {
    fontSize: '213%',
    fontFamily: 'quagmire, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  handWriting: {
    fontFamily: 'adobe-handwriting-tiffany, sans-serif',
    fontSize: '60px',
    lineHeight: '90px',
    paddingBottom: '15px',
    letterSpacing: '0.12em',
    wordSpacing: '0.16em',
  },
}

export default {
  colors: {
    ...baseTheme.colors,
    colors,
  },
  fonts: {
    ...baseTheme.fonts,
    ...fonts,
  },
  backgrounds: {
    surveySlideImage: surveyBgImage,
    surverySlideForeground: 'white 70% opacity',
  },
}
