import { FlagsToggle } from 'flags'

export const isFlagOn = (flagSubcriptions = []) => {
  try {
    return FlagsToggle({
      flagSubcriptions,
      onCode: () => true,
      offCode: () => false,
    })()
  } catch {
    return false
  }
}

export default isFlagOn
