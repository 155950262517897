import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { RootWrapper, TooltipContentWrapper } from './component.styles'

const Tooltip = ({
  className,
  content,
  children,
  dataTestId,
  delay,
  isSiblingHovered,
}) => {
  const [active, setActive] = useState(false)
  let timeout
  let tooltipTitle = 'Tooltip'
  const contentChildren = content?.props?.children
  const contentIsArray = Array.isArray(contentChildren)
  const contentIsString = typeof contentChildren === 'string'

  if (contentChildren) {
    // this if handles tooltips that have a Tooltip Header field in contentful
    // example: [{ props: { children: 'Tooltip Title' }}]
    if (contentIsArray && contentChildren.length > 1) {
      tooltipTitle = contentChildren[0]?.props?.children
      // this if handles tooltips that come in as body copy from contentful
      // example: '__Tooltip Title__ Tooltip body that is the rest of the string.'
    } else if (contentIsString) {
      const underscore = '__'
      const firstUnderscore =
        contentChildren.indexOf(underscore) + underscore.length
      const secondUnderscore = contentChildren.indexOf(
        underscore,
        firstUnderscore,
      )
      tooltipTitle = contentChildren.slice(firstUnderscore, secondUnderscore)
    }
  }

  const ariaLabel = `More Information on ${tooltipTitle}`

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, delay)
  }

  const hideTip = () => {
    clearInterval(timeout)

    if (!isSiblingHovered) setActive(false)
  }

  const switchTip = () => {
    if (active) {
      hideTip()
    } else {
      showTip()
    }
  }

  useEffect(() => {
    if (isSiblingHovered) {
      showTip()
    } else {
      hideTip()
    }
  }, [isSiblingHovered])

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <RootWrapper
      aria-expanded={active}
      aria-label={ariaLabel}
      className={className}
      // When to show the tooltip
      dataTestId={`${dataTestId}-root-wrapper`}
      onBlur={hideTip}
      onClick={switchTip}
      onPointerEnter={showTip}
      onPointerLeave={hideTip}
      role="tooltip"
    >
      {active && (
        <TooltipContentWrapper dataTestId={`${dataTestId}-content-wrapper`}>
          {content}
        </TooltipContentWrapper>
      )}
      {children}
    </RootWrapper>
  )
}

Tooltip.propTypes = {
  Icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  delay: PropTypes.number,
  isSiblingHovered: PropTypes.bool,
}

Tooltip.defaultProps = {
  delay: 100,
}

export default Tooltip
