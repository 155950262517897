import React from 'react'

import { TIMEOUT_INTERVAL } from 'lib/config/version/version1/constants'
import { GoogleAnalytics, resolvePlayerContext } from 'logging'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import DragLayer from '../DragLayer'
import Menu from '../Menu'
import RatioWrapperController from '../RatioWrapperController'
import Slide from '../Slide'
import TimeoutGuard from '../TimeoutGuard'
import TitleBar from '../TitleBar'

import { Wrapper } from './style'
const DATA_TEST_ID = 'Player'

const CFCPlayer = ({ scale, pageTitle, timeout }) => {
  GoogleAnalytics.init({
    trackingId: process.env.GA_TRACKING_ID,
    context: resolvePlayerContext(),
    globalPrefix: 'player',
    pageTitle:
      window.parent?.__GOOGLE_ANALYTICS_FILTER_PROPERTIES__?.title || pageTitle,
  })

  return (
    <>
      <TimeoutGuard timeout={timeout} />
      <DragLayer scale={scale} />
      <RatioWrapperController flags={{ wrapPresentationPlayer: true }}>
        <Wrapper data-testid={DATA_TEST_ID}>
          <TitleBar />
          <Slide />
          <Menu />
        </Wrapper>
      </RatioWrapperController>
    </>
  )
}

CFCPlayer.propTypes = {
  pageTitle: PropTypes.string,
  scale: PropTypes.number,
  timeout: PropTypes.number,
}

CFCPlayer.defaultProps = {
  scale: 1,
  timeout: TIMEOUT_INTERVAL,
}

const mapStateToProps = ({ player }) => ({
  scale: player.ratioWrapper?.scale,
  pageTitle: player.title,
})

export default connect(mapStateToProps, null)(CFCPlayer)
